function moveLearnplan(el) {
    $(el).prop('disabled', true);
    var daysMoved = $(".move-learnplan-input").val();
    var boxId = $('#boxId').val();
    var nonce = getNonce();
    shortInfo(i18n.gettext('Moving...'));
    $.ajax({
        // retry-sensitive
        type: "POST",
        url: "kartensatz-einstellungen-" + boxId,
        data: "pushPlanBy=" + daysMoved + "&nonce=" + nonce,
        success: function (data) {
            shortInfo(data);
            $(el).prop('disabled', false);
        }
    });
}

function studyTargetModal(boxId, optionalDefaultTargetValue) {
    $("#study-target-modal").modal();
    $("#study-target-modal-boxid").val(boxId);
    
    $.ajax({
        type: "GET",
        url: "main",
        data: "getActiveCardboxes=1&subset=active",
        success: function (data) {
            var boxes = JSON.parse(data.trim());
            
            if (boxId == null || typeof boxId === 'undefined') {
                $("#study-target-modal #btnSaveSelectedBoxTargetDate").show();
                $("#study-target-modal #btnSavePreSelectedBoxTargetDate").hide();
                var boxSelectionBlock = '<select style="max-width: 100%;" name="studyTargetSelectedBoxId" id="studyTargetSelectedBoxId">';
                
                $.each(boxes, function (index, box) {
                    // if (box.targetDate != 0) {
                        boxSelectionBlock += '<option value="' + box.cardBoxNbr + '">' + box.cardBoxName + '</option>';
                    // }
                });
                
                boxSelectionBlock += '</select>';
                
                $("#study-target-modal #study-target-modal-cardset-name").html(boxSelectionBlock);
            } else {
                $("#study-target-modal #btnSaveSelectedBoxTargetDate").hide();
                $("#study-target-modal #btnSavePreSelectedBoxTargetDate").show();
                $.each(boxes, function (index, box) {
                    if (box.cardBoxNbr == boxId) {
                        $("#study-target-modal #study-target-modal-cardset-name").html(box.cardBoxName);
                    }
                });
            }
        }
    });
        
    $("#study-target-modal #modal-cardset-target-date").val();
    if (optionalDefaultTargetValue !== undefined) {
        $("#study-target-modal #modal-cardset-target-date").val(optionalDefaultTargetValue);
    }
}



function saveTargetDate(value, optionalBoxIdFieldId) {
    debug("Executing saveTargetDate");
    shortInfo(i18n.gettext("Saving..."));
    var page = $('#pagehash').val();
    var targetDate = $('#cardset-target-date-hidden').val();
    if (value !== undefined) {
        targetDate = value;
    }
    var fieldId = "boxId";
    if (optionalBoxIdFieldId !== undefined) {
        fieldId = optionalBoxIdFieldId;
    }
    var boxId = $('#' + fieldId).val();
    var timestamp=0;
    if ($('#timestamp').length > 0) {
        timestamp = $('#timestamp').val();
    }
    if ($('.calendar').length > 0) {
        $('.calendar').fadeTo(200, 0.75, 'swing');
    }
    $.ajax({
        // retry-sensitive
        type: "POST",
        url: "kartensatz-einstellungen-" + boxId,
        data: "timestamp=" + timestamp + "&targetDate=" + targetDate,
        success: function (data) {
            shortInfo(data);
            $(".settings-container .targetDateEnglish").val(targetDate);
            if (page.startsWith("calendar")) {
                // loadPageToCenter("calendar?showScheduleSettings=true&boxId=" + boxId + "&timestamp=" + timestamp)
                refreshPage();
                /*
                $.ajax({
                    type: "POST",
                    url: "calendar",
                    data: "module=calendar&boxId=" + boxId + "&timestamp=" + timestamp,
                    success: function(data) {
                        $('.calendar').html(data);
                        $('.calendar').fadeTo(100, 1.0, 'swing');
                    }
                });
                */
            } else if ($("#target-date-display-value").length > 0) {
                // $("#target-date-display-value").html(targetDate);
                loadTargetDateAndRecommendation(boxId);
            } else if ($("#kartensatz-block-" + boxId + " .targetDate").length > 0) {
                $("#kartensatz-block-" + boxId + " .targetDateEnglish").val(targetDate);
                if (targetDate == "") {
                    $("#kartensatz-block-" + boxId + " .targetDate").hide();
                } else {
                    $("#kartensatz-block-" + boxId + " .targetDate").show();
                }
                loadTargetDateAndRecommendation(boxId);
                //TODO refresh list of upcoming study target days in .studyTargetListBox
            } else if (targetDate == 0) {
                if ($('.settings-block #cardset-target-date-hidden').length > 0) {
                    $('.settings-block #cardset-target-date-hidden').val(0);
                }
                if ($('.cardset-target-date').length > 0) {
                    $('.cardset-target-date').val(0);
                }
            }
        }
    });
}

function loadTargetDateAndRecommendation(boxId) {
    var url = "cardset-" + boxId + "?getTargetDateInfo=1";
    $.get(url, function(data) {
        var obj = JSON.parse(data);
        if ($("#target-date-display-value").length > 0) {
            $("#target-date-display-value").html(obj.targetDate);
        }
        if ($("#target-date-study-recommendation").length > 0) {
            $("#target-date-study-recommendation").html(obj.recommendation);
        }
        if ($("#kartensatz-block-" + boxId + " .targetDate").length > 0) {
            $("#kartensatz-block-" + boxId + " .targetDate").html(obj.targetDate);
        }
    });
}

//function saveCustomSchedule() {
//    shortInfo(i18n.gettext("Saving..."));
//    var enableCustomSchedule = $("#enableCustomSchedule").is(":checked");
//    var firstAddition = $('#firstAddition').val();
//    var secondAddition = $('#secondAddition').val();
//    var thirdAddition = $('#thirdAddition').val();
//    var fourthAddition = $('#fourthAddition').val();
//    var fifthAddition = $('#fifthAddition').val();
//    var sixthAddition = $('#sixthAddition').val();
//    var seventhAddition = $('#seventhAddition').val();
//    var eighthAddition = $('#eighthAddition').val();
//    var ninthAddition = $('#ninthAddition').val();
//    var tenthAddition = $('#tenthAddition').val();
//    var boxId = $('#boxId').val();
//    $.ajax({
//        type: "POST",
//        url: "kartensatz-einstellungen-" + boxId,
//        data: "enableCustomSchedule=" + enableCustomSchedule + "&firstAddition=" + firstAddition + "&secondAddition=" + secondAddition
//                + "&thirdAddition=" + thirdAddition + "&fourthAddition=" + fourthAddition
//                + "&fifthAddition=" + fifthAddition + "&sixthAddition=" + sixthAddition
//                + "&seventhAddition=" + seventhAddition + "&eighthAddition=" + eighthAddition
//                + "&ninthAddition=" + ninthAddition + "&tenthAddition=" + tenthAddition,
//        success: function (data) {
//            shortInfo(data);
//            refreshPage();
//        },
//        error: function () {
//            shortInfo(i18n.gettext("Error while saving the intervals."));
//        }
//    }); // end ajax
//}

function copyCardboxToUser() {
    var userId = $('.copy-cardbox-to-user-input').val();
    var boxId = $('#boxId').val();
    var evenIfCopyExists = $("#evenIfCopyExists").is(":checked") ? 1 : 0;
    var nonce = getNonce();
    shortInfo(i18n.gettext('Copying...'));
    $.ajax({
        // retry-sensitive
        type: "POST",
        url: "kartensatz-einstellungen-" + boxId,
        data: "assignCopyTo=" + userId + "&evenIfCopyExists=" + evenIfCopyExists + "&nonce=" + nonce,
        success: function (data) {
            shortInfo(data);
        }
    });
}

function assignCardboxToUser() {
    var userId = $('.assign-cardbox-to-user-input').val();
    var boxId = $('#boxId').val();
    var nonce = getNonce();
    shortInfo(i18n.gettext('Assigning cardset...'));
    $.ajax({
        // retry-sensitive
        type: "POST",
        url: "kartensatz-einstellungen-" + boxId,
        data: "assignOriginalTo=" + userId + "&nonce=" + nonce,
        success: function (data) {
            shortInfo(data);
        }
    });
}

function updateCopyOfCardset() {
    var boxId = $('#boxId').val();
    var nonce = getNonce();
    shortInfo(i18n.gettext('Updating cardset...'));
    $.ajax({
        // retry-sensitive
        type: "POST",
        url: "kartensatz-" + boxId,
        data: "updateCopy=1&nonce=" + nonce,
        success: function (data) {
            shortInfo(data);
        }
    });
}

function setCardboxOption(elem) {
    var attribute = $(elem).data('id');
    var checked = $(elem).prop('checked') == true ? 1 : 0;
    var boxId = $('#boxId').val();
    debug("Setting cardbox option for box " + boxId + ": " + attribute + " to " + checked);
    shortInfo(i18n.gettext('Setting Option...'));
    $.ajax({
        type: "POST",
        url: "kartensatz-einstellungen-" + boxId,
        data: "setAttribute=" + attribute + "&value=" + checked,
        success: function (data) {
            shortInfo(data);
        }
    });
}

function resetKeywordAssignment() {
    var boxId = $('#boxId').val();
    shortInfo(i18n.gettext('Please wait...'));
    $.ajax({
        type: "POST",
        url: "kartensatz-einstellungen-" + boxId,
        data: "resetKeywordAssignment=1",
        success: function (data) {
            shortInfo(data);
        }
    });
}

function reInitGroupHasKeywordForAll() {
    shortInfo(i18n.gettext('Please wait...'));
    $.ajax({
        type: "POST",
        url: "settings",
        data: "reInitGroupHasKeywordForAll=1",
        success: function (data) {
            shortInfo(data);
        }
    });
}

function setCardboxOptionInput(elem) {
    var attribute = $(elem).data('id');
    var value = $(elem).val();
    var boxId = $('#boxId').val();
    debug("Setting cardbox option for box " + boxId + ": " + attribute + " to " + value);
    shortInfo(i18n.gettext('Setting Option...'));
    $.ajax({
        type: "POST",
        url: "kartensatz-einstellungen-" + boxId,
        data: "setAttribute=" + attribute + "&value=" + value,
        success: function (data) {
            shortInfo(data);
        }
    });
}

function setCardboxLanguage(elem) {
    var attribute = $(elem).data('id');
    var value = $(elem).val();
    var boxId = $('#boxId').val();
    shortInfo(i18n.gettext('Setting Language...'));
    $.ajax({
        type: "POST",
        url: "kartensatz-einstellungen-" + boxId,
        data: "setLanguage=" + attribute + "&value=" + value,
        success: function (data) {
            shortInfo(data);
        }
    });
}