/* ADD STUDYPALS MODAL */

// open add studypals modal button
function studypalsModal(el) {
    var boxId = $(el).data("boxid");
    $("#add-studypals-modal").modal();

    var url = 'neuer-kartensatz'
    var mode = "new-cardbox";
    if (!isNaN(boxId) && boxId != "") {
        url = "kartensatz-mitlerner-" + boxId;
        mode = "existing-cardbox";
    }

    debug("Mode is: " + mode);

    if (mode != "new-cardbox" || $("#add-studypals-modal-content").html().trim() == "") {

        $.ajax({
            type: "POST",
            url: url,
            data: "module=add-studypals",
            success: function (data) {
                $("#add-studypals-modal-content").html(data);
                $("#add-studypals-modal-boxid").val(boxId);

                $("#add-studypals-modal").data("mode", mode);
                setTimeout(function () {
                    $("#add-studypals-modal").find("input").focus();
                }, 0);

                var url = 'freunde?json=1'

                if (!isNaN(boxId) && boxId != "") {
                    url = "kartensatz-mitlerner-" + boxId;
                    $.ajax({
                        type: "GET",
                        url: "cardset-" + boxId + "?getAccessLink=true",
                        success: function (data) {
                            $("#add-studypals-modal #accessLinkCopyField").val(data);
                        }
                    });
                }

                $.ajax({
                    type: "GET",
                    url: url,
                    data: "getFriendsToInvite=1",
                    success: function (data) {
                        debug("Successfully loaded friends who could be added to box.")
                        var friends = JSON.parse(data);
                        if (friends.length == 0) {
                            $("#add-studypals-modal-friends").hide();
                        } else {
                            $("#add-studypals-modal-friends").show();
                        }
                        var friendsString = '';
                        $.each(friends, function (index, friend) {
                            // debug(friend.userId + " - " + friend.userName + " - " + friend.avatarUrl);
                            friendsString += '<span class="user-display click" style="margin: 10px;width: 100px;height: 100px;" data-userid="' + friend.userId + '" onclick="selectFriendForCoLearning(this);">\
                            <div class="userpic medium" style="width:40px;height:40px;background-image:url(\'' + friend.avatarUrl + '\')"></div>\
                            <p class="username">' + friend.userName + '</p><div class="pro-badge small js-addMe-check" style="display:none; left: 54px;top: 24px;"><i class="fa fa-check"></i></div></span>';
                        });
                        $("#add-studypals-modal-friends-addablefriends").html(friendsString);
                    }
                });

                if (mode == 'new-cardbox') {
                    $("#add-studypals-modal-embed").hide();
                    $("#add-studypals-modal .action-btn").hide();
                } else {
                    $("#add-studypals-modal-embed").show();
                    $("#add-studypals-modal .action-btn").show();
                }
            }
        });
    }
}

function selectFriendForCoLearning(el) {
    if ($(el).hasClass('js-addMe')) {
        $(el).removeClass('js-addMe');
        $(el).find(".js-addMe-check").hide();
    } else {
        $(el).addClass('js-addMe');
        $(el).find(".js-addMe-check").show();
    }
    ;
}

function addStudypalBtnClick() {
    $("#add-studypal-btn-div").before(' \
            <div class="input-x w100"> \
            <input type="text" class="js-studypal-info w100" tabindex="20" onkeypress="addStudypalKeyPress(event);"> \
            <button class="remove-btn" onclick="removeStudypalBtnClick(this)"> \
                    <i class="fa fa-minus-square-o"></i> \
            </button> \
        </div> \
        ');
    $(".modal").find("input:last").focus();
}

function addStudypalKeyPress(e) {
    if (e.keyCode == 13) {
        addStudypalBtnClick();
    }
}

function removeStudypalBtnClick(el) {
    $(el).parent().remove();
}

function studypalsModalAction() {
    if ($("#add-studypals-modal").data("mode") === "existing-cardbox") {

        var colearners = [];
        $('#add-studypals-modal').find(".js-studypal-info").each(function () {
            colearners.push(encodeURIComponent($(this).val()));
        });

        var colearnerIds = [];
        $('#add-studypals-modal').find(".js-addMe").each(function () {
            colearnerIds.push($(this).data("userid"));
        });

        var boxId = $("#add-studypals-modal-boxid").val();
        flashModal(i18n.gettext('Adding Colearners...'));
        $.ajax({
            type: "POST",
            url: "kartensatz-karten-" + boxId,
            data: "colearners=" + colearners + "&colearnerIds=" + colearnerIds,
            success: function (data) {
                flashModal(data);
                var pagehash = $('#pagehash').val();
                if (pagehash.startsWith("cardset-colearners")) {
                    refreshPage();
                }
            }
        });

        setTimeout(function () {
            $("#add-studypals-modal").find("input").val('');
            $("#add-studypals-modal").find(".input-x:gt(0)").remove();
        }, 200);

    } else {
        flashModal(i18n.gettext('Co-learners will be invited after creating the cardset.'));
    }

}

function copyAccessLink(id) {
    var copyText = document.getElementById(id);

    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /*For mobile devices*/

    /* Copy the text inside the text field */
    document.execCommand("copy");

    /* Alert the copied text */
    debug("Copied the text: " + copyText.value);
    shortInfo(i18n.gettext("Copied link to clipboard."));
}

function regenerateAccessLink() {
    var boxId = $("#add-studypals-modal-boxid").val();
    var doIt = confirm(i18n.gettext("If you regenerate the access link, the old link will not work any more. Continue?"));
    if (doIt) {
        $.ajax({
            type: "GET",
            url: "cardset-" + boxId + "?regenerateAccessLink=true",
            success: function (data) {
                if (data.trim() != "") {
                    $("#add-studypals-modal #accessLinkCopyField").val(data);
                    shortInfo(i18n.gettext("Access link updated."));
                }
            }
        });
    }
}