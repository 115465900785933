function setNewPassword() {
    $('.newPasswordInput').toggle();
}

function saveNewPassword() {
    var password = $("#newPassword").val();
    var passwordRepetition = $("#newPasswordRepetition").val();
    var userId = $("#userId").val();
    $.ajax({
        type: "POST",
        url: "profile-" + userId,
        data: "newPassword=" + password + "&newPasswordRepetition=" + passwordRepetition,
        success: function (data) {
            shortInfo(data, 4000);
        },
        error: function () {
            shortInfo(i18n.gettext("Error while saving the setting."));
        }
    }); // end ajax
}

function setNewProLimit() {
    $('.newProLimitInput').toggle();
}

function saveNewProLimit() {
    var proDay = $('#proDay').val();
    var proMonth = $('#proMonth').val();
    var proYear = $('#proYear').val();
    var userId = $("#userId").val();
    $.ajax({
        type: "POST",
        url: "profile-" + userId,
        data: "proDay=" + proDay + "&proMonth=" + proMonth + "&proYear=" + proYear,
        success: function (data) {
            shortInfo(data, 4000);
            loadPageToCenter("profile-" + userId);
        },
        error: function () {
            shortInfo(i18n.gettext("Error while saving the setting."));
        }
    }); // end ajax
}

function deleteUser() {
    var userId = $("#userId").val();
    var r = confirm(i18n.gettext("Really mark user for deletion?"));
    if (r == true) {
        $(".page-container-inner").html("");
        showAjaxLoader(".page-container-inner");
        $.ajax({
            type: "POST",
            url: "profile-" + userId,
            data: "delete=" + 1,
            success: function (data) {
                shortInfo(data, 4000);
                loadPageToCenter("profile-" + userId);
            },
            error: function () {
                shortInfo(i18n.gettext("Error while saving the setting."));
            }
        }); // end ajax
    }
}

function deleteAllUserData() {
    var userId = $("#userId").val();
    var r = confirm(i18n.gettext("Really delete the user and all associated data?"));
    if (r == true) {
        $(".page-container-inner").html("");
        showAjaxLoader(".page-container-inner");
        $.ajax({
            type: "POST",
            url: "profile-" + userId,
            data: "deleteAllUserData=" + 1,
            success: function (data) {
                shortInfo(data, 4000);
                loadPageToCenter("profile-" + userId);
            },
            error: function () {
                shortInfo(i18n.gettext("Error while saving the setting."));
            }
        }); // end ajax
    }
}

function lockUser() {
    var userId = $("#userId").val();
    $.ajax({
        type: "POST",
        url: "profile-" + userId,
        data: "lock=" + 1,
        success: function (data) {
            shortInfo(data, 4000);
            loadPageToCenter("profile-" + userId);
        },
        error: function () {
            shortInfo(i18n.gettext("Error while saving the setting."));
        }
    }); // end ajax
}

function unlockUser() {
    var userId = $("#userId").val();
    $.ajax({
        type: "POST",
        url: "profile-" + userId,
        data: "unlock=" + 1,
        success: function (data) {
            shortInfo(data, 4000);
            loadPageToCenter("profile-" + userId);
        },
        error: function () {
            shortInfo(i18n.gettext("Error while saving the setting."));
        }
    }); // end ajax
}

function makeStudentWorker(userId, make) {
    $.ajax({
        type: "POST",
        url: "profile-" + userId,
        data: "makeStudentWorker=" + make,
        success: function (data) {
            shortInfo(data, 4000);
            loadPageToCenter("profile-" + userId);
        },
        error: function () {
            shortInfo(i18n.gettext("Error while saving the setting."));
        }
    }); // end ajax
}

function showUserCardsets(el) {
    var userId = $(el).data('userid');
    var userName = $(el).data('username');
    var content = '<img src="' + $("html").data("approot_images") + 'ajax-loader-2.gif" style="padding: 10px; vertical-align: middle; display:none;">';
    var title = i18n.gettext('Cardsets of') + ' <b>' + userName + '</b>';
    generalPurposeModal(content, title);
    $.ajax({
        type: "GET",
        url: 'freunde',
        data: "getCardsetsOfFriend=" + userId,
    }).done(function (data) {
        var obj = JSON.parse(data);
        var content = '<br><br>';
        if (obj.length > 0) {
            content += '<ul style="margin-left:40px;">';
            for (var i = 0; i < obj.length; i++) {
                var boxName = obj[i]['cardBoxName'];
                var boxId = obj[i]['cardBoxNbr'];
                var bought = obj[i]['bought'];
                content += '<li><a class="bright-link click" href="kartensatz-' + boxId + '" onclick="closeModal();">' + boxName;
//                if (bought == 1) {
//                    content += ' <i class="fa fa-shopping-cart" title="Diesen Kartensatz kannst Du in unserem Shop kaufen."></i>';
//                }
                content += '</a></li>';
            }
            content += '</ul>';
        } else {
            content += '<i>' + i18n.gettext('No available cardsets found.') + '</i>';
        }
        var title = i18n.gettext('Cardsets of') + ' <b>' + userName + '</b>';
        $("#general-purpose-modal-content").html(title + content);
        // generalPurposeModal(content, title);
    });
}

function initAssignPackageForm() {
    $("#assignPackageShowForm").hide();
    $("#assignPackageForm").show();
    $.ajax({
        type: 'GET',
        url: 'manage_packages',
        data: 'getAvailablePackages=1'
    }).done(function (data) {
        var htmlString = '';
        var obj = JSON.parse(data);
        if (obj.length > 0) {
            for (var i=0; i < obj.length; i++) {
                var packageId = obj[i]['packageKey'];
                var packageName = obj[i]['title'];
                htmlString += '<option value="' + packageId + '">(' + packageId + ') ' + packageName + '</option>';
            }
        }
        $("#assignPackage").html(htmlString);
    });
}

function assignPackage() {
    flashModal(i18n.gettext("Processing, please wait..."), 10000);
    var userId = $("#userId").val();
    var packageId = $("#assignPackage").val();
    var numOfDays = $("#assignPackageForDays").val();
    $.ajax({
        type: 'POST',
        url: 'profile-' + userId,
        data: 'assignPackage=' + packageId + '&numOfDays=' + numOfDays
    }).done(function (data) {
        flashModal(data);
        refreshPage();
        // loadPageByAjax("profile-" + userId);
    });
}

// EDITABLE USER PROFILE NAME
function editProfileName() {
    $(".profilefirstname.input").attr("contenteditable",true);
    $(".profilelastname.input").attr("contenteditable",true);
    $(".namefieldindicator").show();
    $(".edit-profile-name-btn").hide();
    $(".save-profile-name-btn").show();
    setTimeout(function() {
        $(".profilefirstname.input").focus();
    },0);
}

function saveProfileName() {
    $(".profilefirstname.input").attr("contenteditable",false);
    $(".profilelastname.input").attr("contenteditable",false);
    $(".namefieldindicator").hide();
    $(".edit-profile-name-btn").show();
    $(".save-profile-name-btn").hide();
    
    var profileFirstName = encodeURIComponent($(".profilefirstname").html());
    var profileLastName = encodeURIComponent($(".profilelastname").html());
    
    $.post("profile", "userFirstName=" + profileFirstName + "&userLastName=" + profileLastName, function(data) {
        shortInfo(data);
    });
}