$(window).scroll(function () {
    $("#shoppingcart").css(
            {
                "margin-top": ($(window).scrollTop()) + "px",
                "margin-left": ($(window).scrollLeft()) + "px"
            }
    );
});

function addBoxToShoppingCart(el, numericResult) {
    if (typeof numericResult === 'undefined') {
        numericResult = false;
    }

    var mystr = $(el).attr('id');
    var myarr = mystr.split("-");
    var boxId = myarr[1];

    if ($(".addToCart-" + boxId).length > 0) {
        $(".addToCart-" + boxId).removeClass("cta");
        $(".addToCart-" + boxId).addClass("grey-light");
    }

    if (!numericResult) {
        // $(".page-container").addClass("wide");
        $(".shopping-cart-container").show();
    }

    addToShoppingCart('box', boxId, 1, 0, numericResult);
    // shortInfo(i18n.gettext("Added to shopping cart."));
}

function addPackageToShoppingCart(el, numericResult) {
    var packageId = $(el).data('packageid');
    var months = $(el).data('months');

    if ($(".addToCart-" + packageId).length > 0) {
        $(".addToCart-" + packageId).removeClass("cta");
        $(".addToCart-" + packageId).addClass("grey-light");
    }

    addToShoppingCart('package', packageId, 1, months, numericResult);
}

function addItemToShoppingCart(el) {
    var type = $(el).data("type");
    var id = $(el).data("id");
    var number = $(el).data("number");
    var months = $(el).data("months");
    addToShoppingCart(type, id, number, months);
}

function removePackageFromShoppingCart(el) {
    var packageId = $(el).data('id');
    if ($(".addToCart-" + packageId).length > 0) {
        $(".addToCart-" + packageId).removeClass("grey-light");
        $(".addToCart-" + packageId).addClass("cta");
    }
    var nonce = getNonce();
    $.ajax({
        // retry-sensitive
        type: "POST",
        url: "shoppingcart",
        data: "delete_package=" + packageId + "&nonce=" + nonce + "&module=shopping-cart-bar",
        success: function (data) {
            $("#shopping-cart-bar-container").html(data);
            scbNiceScrollInit();
        }
    });
}

function removeFromShoppingCart(el) {

    var type = $(el).data('type');
    var id = $(el).data('id');

    if (type == "box" || type == "package") {
        if ($(".addToCart-" + id).length > 0) {
            $(".addToCart-" + id).removeClass("grey-light");
            $(".addToCart-" + id).addClass("cta");
        }
    }

    var nonce = getNonce();
    $.ajax({
        // retry-sensitive
        type: "POST",
        url: "shoppingcart",
        data: "delete_item=" + type + "&id=" + id + "&nonce=" + nonce + "&module=shopping-cart-bar",
        success: function (data) {
            $("#shopping-cart-bar-container").html(data);
            scbNiceScrollInit();
        }
    });
}

function makeOrder(el) {

    var errorText = '';

    var missingFields = [];
    if ($('input[name=first_name]').length > 0 && $('input[name=first_name]').val() == '') {
        missingFields.push(i18n.gettext("Prename"));
    }
    if ($('input[name=last_name]').length > 0 && $('input[name=last_name]').val() == '') {
        missingFields.push(i18n.gettext("Surname"));
    }
    if ($('input[name=address]').length > 0 && $('input[name=address]').val() == '' && $("#row_street").is(":visible")) {
        missingFields.push(i18n.gettext("Address (street and number)"));
    }
    if ($('input[name=zip]').length > 0 && $('input[name=zip]').val() == '' && $("#row_zip").is(":visible")) {
        missingFields.push(i18n.gettext("Postal Code"));
    }
    if ($('input[name=city]').length > 0 && $('input[name=city]').val() == '' && $("#row_city").is(":visible")) {
        missingFields.push(i18n.gettext("City"));
    }
    if ($('input[name=country]').length > 0 && $('input[name=country]').val() == '' && $("#row_country").is(":visible")) {
        missingFields.push(i18n.gettext("Country"));
    }
    if ($('input[name=email]').length > 0 && $('input[name=email]').val() == '') {
        missingFields.push(i18n.gettext("E-Mail"));
    }
    if ($('input[name=company]').length > 0 && $('input[name=company]').val() == '' && $("#row_companyName").is(":visible")) {
        missingFields.push(i18n.gettext("Company"));
    }
    if ($('input[name=nip]').length > 0 && $('input[name=nip]').val() == '' && $("#row_nip").is(":visible") && $('input[name=nip]').hasClass('mandatory')) {
        missingFields.push(i18n.gettext("NIP"));
    }

    if (missingFields.length > 0) {
        errorText += i18n.gettext("Please fill the following fields as well:") + '<br><br><ul style="margin-left:20px;"><li>' + missingFields.join("</li><li>") + "</li></ul>";
    }

    if (!$('input[name=acceptsLicence]').is(":checked")) {
        errorText += "<br>" + i18n.gettext("Please accept the privacy policy and the usage conditions to conduct your purchase.");
    }

    if (errorText != '') {
        generalPurposeModal(errorText);
    } else {
        $('#buyButton').disabled = true;
        $('#buyButton').html(i18n.gettext('Processing data...'));
        $('#center').fadeTo(300, 0.5);
        var nonce;
        if (typeof (getNonce) == "function") {
            nonce = getNonce();
        }

        var addToSalesManago = false;
        if ($(".newsletter-check").length > 0) {
            addToSalesManago = $("#order-newsletter").is(":checked");
        }
        
        var recurringPayment = $("#recurringPayment").is(":checked");

        $.ajax({
            // retry-sensitive
            type: "POST",
            url: "checkout",
            data: $("#buyForm").serialize() + "&recurringPayment=" + recurringPayment + "&dynReq=1" + "&nonce=" + nonce
        }).done(function (data) {
            // window.location = "kartensatz-" + boxId;

            if (addToSalesManago) {
                $.get("salesManago?do=shop", function (salesManagoData) {
//                    var x = window.open('', '', 'width=600, height=600');
//                    x.document.open();
//                    x.document.write(salesManagoData);
//                    x.document.close();
                    $('#center').html(data);
                    $('#center').fadeTo(300, 1.0);
                    window.scrollTo(0, 0);
                });
            } else {
                $('#center').html(data);
                $('#center').fadeTo(300, 1.0);
                window.scrollTo(0, 0);
            }

        }).fail(function (xhr, status) {
            $('#buyButton').disabled = false;
            $('#buyButton').html(i18n.gettext('Buy now'));
        });
    }
}

function couponCodeKeypress(e, couponcode) {
    if (e.keyCode == 13) {
        registerCouponDirectly(couponcode);
    }
}

function registerCouponDirectly(couponcode) {
    var nonce = getNonce();
    
    var mailMarketingConsent = false;
    if ($("#mailMarketingConsent").length > 0) {
        if ($("#mailMarketingConsent").is(":checked")) {
            mailMarketingConsent = true;
        } else {
            generalPurposeModal(i18n.gettext("Registering for the newsletter is required to convert this coupon!"));
            return;
        }
    }
    
    if ($("html").data("loggedin") != "1") {
        window.location.href = "register_or_login?couponcode=" + couponcode + "&mailMarketingConsent=" + mailMarketingConsent;
    } else {
        flashModal(i18n.gettext('Processing coupon codes...'));
        $.ajax({
            // retry-sensitive
            type: "POST",
            url: "coupon",
            data: "couponcode=" + couponcode + "&mailMarketingConsent=" + mailMarketingConsent + "&nonce=" + nonce,
            success: function (data) {
                var obj = JSON.parse(data.trim());
                
                generalPurposeModal(obj.resultMessage);
                
                if (obj.hasOwnProperty('goto') > 0) {
                    loadPageByAjax(obj.goto);
                } else if (obj.resultCode) {
                    loadPageByAjax('main');
                }
            }
        });
    }
}

function updateSum() {
    var sum = 0;
    for (var i = 0; i < document.cardboxesForm.elements.length; i++) {
        var e = document.cardboxesForm.elements[i];
        if (e.type == 'checkbox') {
            if (e.checked == true) {
                // alert(e.name);
                // alert(document.getElementById('price_' + e.value).value);
                if (e.name == 'cardbox[]'
                        && document.getElementById('price_' + e.value) != null) {
                    sum += parseInt(document.getElementById('price_' + e.value).value);
                }
                if (e.name == 'audio[]'
                        && document.getElementById('price_audio_' + e.value) != null) {
                    sum += parseInt(document.getElementById('price_audio_'
                            + e.value).value);
                }
                if (e.name == 'package[]'
                        && document.getElementById('price_package_' + e.value) != null) {
                    sum += parseInt(document.getElementById('price_package_'
                            + e.value).value);
                }
            }
        }
    }

    sum = sum / 100;
    sum = sum.toString();
    if ((sum.lastIndexOf(".") != -1)
            && (sum.length - sum.lastIndexOf(".") - 1 == 1))
        sum += "0";
    if (sum == "00")
        sum = "0";
    if (sum.lastIndexOf(".") == -1)
        sum += ".00";
    document.getElementById('sum').innerHTML = sum;
}

function CheckAllBoxes() {
    for (var i = 0; i < document.cardboxesForm.elements.length; i++) {
        var e = document.cardboxesForm.elements[i];
        if (e.type == 'checkbox') {
            e.checked = true;
        }
    }
}

function UnCheckAllBoxes() {
    for (var i = 0; i < document.cardboxesForm.elements.length; i++) {
        var e = document.cardboxesForm.elements[i];
        if (e.type == 'checkbox') {
            e.checked = false;
        }
    }
}

//function toggleShoppingCart(item) {
//    var nonce = getNonce();
//    $.ajax({
//        // retry-sensitive
//        type: "POST",
//        url: "shoppingcart",
//        data: item + "&nonce=" + nonce + "&module=shopping-cart-bar",
//        success: function (data) {
//            $("#shopping-cart-bar-container").html(data);
//        }
//    });
//}

function addToShoppingCart(type, id, number, months, numericResult) {
    debug("Adding product " + type + " to shopping cart!");
    if (typeof number === 'undefined') {
        number = 1;
    }
    if (typeof months === 'undefined') {
        months = 0;
    }

    var itemParam = "addItem=" + type + "&itemId=" + id + "&itemNumer=" + number + "&itemMonths=" + months;

    if (typeof numericResult === 'undefined') {
        numericResult = false;
    }
    var requestParam = "&module=shopping-cart-bar";
    if (numericResult) {
        requestParam = "&getNumOfItems=1";
    }
    debug("Data of request: " + itemParam + requestParam);
    $.ajax({
        type: "POST",
        url: "shoppingcart",
        data: itemParam + requestParam,
        success: function (data) {
            debug("This is a success.");
            if (numericResult) {
                $(".shopping-cart-btn-counter").show();
                $(".shopping-cart-btn-counter").html(data);
                shortInfo(i18n.gettext('The item was added to the shopping cart.'), 3000);
            } else {
                if ($("#numOfItemsInShoppingCart").length > 0) {
                    shortInfo(i18n.gettext('The item was added to the shopping cart.'), 3000);
                    $(".js-show-shopping-cart-bar-btn").addClass("pulse");
                    $(".js-show-shopping-cart-bar-btn").one("webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend", function () {
                        $(this).removeClass("pulse");
                    });
                    $("#shopping-cart-bar-container").html(data);
                    scbNiceScrollInit();
                }
            }
        }
    });
}

function showHideShoppingCart(fileName) {
    if (fileName == 'catalog') {
        $('#shoppingcart').show();
    } else {
        if (document.getElementById('numOfItemsInShoppingCart').innerHTML == 0) {
            $('shoppingcart').hide();
        }
    }
}

function careerLandingOrderBtnClick() {
    $.ajax({
        type: "POST",
        url: "shoppingcart",
        data: "package=6&add=1&module=shopping-cart-bar",
        success: function (data) {
            if ($("html").data("loggedin") != "1") {
                window.location.href = "register_or_login";
            } else {
                window.location.href = "checkout";
            }
        }
    });
}

function listBoxesInShop(searchTerm, orderBy) {

    $(".shop-cardsets-area").fadeTo(200, 0.75, 'swing');

    // the searchTerm can be a publisher name, a topic name or general search term

    $.post("shop", {searchTerm: searchTerm, orderBy: orderBy, module: "shop-cardsets"}).done(function (data) {
        $(".shop-cardsets-area").html(data);
        $(".shop-cardsets-area").fadeTo(100, 1, 'swing');
    }).fail(function () {
        flashModal(i18n.gettext("Shop content could not be loaded."));
    }).complete(function () {
        $(".shop-cardsets-area").fadeTo(100, 1, 'swing');
        window.scrollTo(0, 0);
    });

}

function storeSearchInputKeyPress(e) {
    if (e.keyCode == 13) {
        storeSearch();
    }
}

function storeSearch() {
    var searchTerm = $("#storeSearchInput").val();
    $('#center').fadeTo(200, 0.75, 'swing');
    $.ajax({
        type: "POST",
        url: "shop",
        data: "searchTerm=" + searchTerm + "&module=shop-cardsets"
    }).done(function (data) {
        $('.shop-cardsets-area').html(data);
    }).complete(function () {
        $('#center').fadeTo(100, 1.0, 'swing');
    });
}

function cancelOrder(orderId) {
    shortInfo(i18n.gettext("Processing..."));
    $.ajax({
        type: "GET",
        url: "orders?cancel=" + orderId,
    }).done(function (data) {
        refreshPage();
    });
}

function refundOrder(orderId) {
    shortInfo(i18n.gettext("Processing..."));
    $.ajax({
        type: "GET",
        url: "orders?refund=" + orderId,
    }).done(function (data) {
        flashModal(data);
        refreshPage();
    });
}

function refundOrderPlayStore(orderId) {
    var baseUrl = "manage_orders_playstore";
}

function refundOrderItunes(orderId) {
    var baseUrl = "manage_orders_itunes";
}

function cancelStripeSubscription(subscriptionId) {

    var r = confirm(i18n.gettext("Would you really like to unsubscribe?"));
    if (r) {
        $('#center').fadeTo(200, 0.75, 'swing');
        $.ajax({
            type: "POST",
            url: "manage_subscription",
            data: "stripeUnsubscribe=" + subscriptionId
        }).done(function (data) {
            flashModal(data);
            refreshPage();
        }).complete(function () {
            $('#center').fadeTo(100, 1.0, 'swing');
        });
    }

}

function cancelPaypalSubscription(subscriptionId) {

    var r = confirm(i18n.gettext("Would you really like to unsubscribe?"));
    if (r) {
        $('#center').fadeTo(200, 0.75, 'swing');
        $.ajax({
            type: "POST",
            url: "manage_subscription",
            data: "paypalUnsubscribe=" + subscriptionId
        }).done(function (data) {
            flashModal(data);
            refreshPage();
        }).complete(function () {
            $('#center').fadeTo(100, 1.0, 'swing');
        });
    }

}

function updateRecurringPaymentMethod() {
    var selectedPaymentMethod = $("input:radio[name=repetico_paymethod]:checked").val();
    var recurringPaymentChecked = $("#recurringPayment").is(":checked");
    if (recurringPaymentChecked) {
        if (selectedPaymentMethod == "transcription" || selectedPaymentMethod == "dotpay") {
            $('input:radio[name=repetico_paymethod]').val(['paypal']);
        }
        // $('#paymethod_paypal').attr('disabled', 'disabled');
        $('#buyAsCouponCode').removeAttr('checked');
        $('#buyAsCouponCode').attr('disabled', 'disabled');
        $("#couponcode").val("");
        $("#couponcode").attr('disabled', 'disabled');
        $(".extendedSubscriptionExplanation").show();
    } else {
        // $('#paymethod_paypal').removeAttr('disabled');
        $('#buyAsCouponCode').removeAttr('disabled');
        $("#couponcode").removeAttr('disabled');
        $(".extendedSubscriptionExplanation").hide();
    }
}

function updateRecurringOptionAvailable() {
    
}