function wat() {
    if ($(".cards-wrong-quantity").length > 0 && $(".cards-wrong-quantity").html() > 100 && $(".cards-correct-quantity").length == 0) {
        
        let a = ["wat", "Angry", "DisappearHomerSimpson", "ExcuseMeWhat", "OhNoFire", "PicardFacePalm", "SadDoctorWho", "SadSadness", "Unimpressed"];
        let i = Math.floor(Math.random() * a.length);
        let r = a[i];
        
        var imgCode = '<img src="' + $("html").data("approot_images") + 'fun/negative/' + r + '.gif" id="watimg" style="display: none; position: fixed; bottom: 0; left: auto; right: auto; z-index: 65535;">';

        $("#watdiv").html(imgCode);

        $("#watimg").show();
        setTimeout(function () {
            $("#watimg").hide();
            $("#watdiv").html('');
        }, 3600);
    } else if ($(".cards-correct-quantity").length > 0 && $(".cards-correct-quantity").html() > 100 && $(".cards-wrong-quantity").length == 0) {
        
        let a = ["clap", "HappyJenniferAniston", "Kermit", "ProudOfYou", "RhymingLeonardoDicaprio", "YoutubeMeme"];
        let i = Math.floor(Math.random() * a.length);
        let r = a[i];
        
        var imgCode = '<img src="' + $("html").data("approot_images") + 'fun/positive/' + r + '.gif" id="watimg" style="display: none; position: fixed; bottom: 0; left: auto; right: 0; z-index: 65535;">';

        $("#watdiv").html(imgCode);

        $("#watimg").show();
        setTimeout(function () {
            $("#watimg").hide();
            $("#watdiv").html('');
        }, 3600);
    }
}