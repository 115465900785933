function deleteCardboxModal(boxId, boxName) {
    $("#delete-cardbox-modal").modal();
    $("#delete-cardbox-modal #deleteCardboxId").val(boxId);
    $("#delete-cardbox-modal #deleteCardboxNameQuestion").html(sprintf(i18n.gettext('Do you really want to delete the cardset "%s"?'), boxName)
            + "<br><br>"
            + i18n.gettext("You can restore the cardset later if you select \"Manage deleted cardsets\" in the folder selection."));
}

function deleteCardbox() {
    var boxId = $("#delete-cardbox-modal #deleteCardboxId").val();
    flashModal(i18n.gettext('Deleting cardset...'));
    $.ajax({
        type: "POST",
        url: "kartensatz-einstellungen-" + boxId,
        data: "deleteBox=1",
        success: function (data) {
            if (data == 1) {
                flashModal(i18n.gettext("Cardset deleted."));
            } else {
                flashModal(i18n.gettext("The cardset could not be deleted."));
            }
            var pagehash = $('#pagehash').val();
            if (pagehash.startsWith('cardset') || pagehash.startsWith('card')) {
                loadPageByAjax('main');
            } else {
                refreshPage();
            }
        }
    });
}
