function refreshNumOfCardsInLearnOptionsModal(boxId) {

    debug("refreshNumOfCardsInLearnOptionsModal");

    var kindOfTest = $("#learn-options-modal-kindoftest-selector").val();
    var numOfCardsToLearnDisplay;
    var daysToWait;

    var url = "lernen-info?learnmodeInfo=1&kindOfTest=" + kindOfTest;
    if (!isNaN(parseFloat(boxId)) && isFinite(boxId) && boxId > 0) {
        url = url + "&boxId=" + boxId;
    }

    $.get(url, function (data) {
        var obj = JSON.parse(data.toString().trim());
        var numOfQuestions = obj.numOfQuestions;
        if ($("#learn-options-modal-number-selector").length > 0) {
            var learningMaxNumber = 0;
            learningMaxNumber = $("#learn-options-modal-number-selector").val();
            debug("Restriction to: " + learningMaxNumber);
            if (learningMaxNumber > 0) {
                numOfQuestions = Math.min(learningMaxNumber, numOfQuestions);
                debug("So - num of cards to learn: " + learningMaxNumber);
            }
        } else {
            debug("No restriction of number of cards in this session.");
        }
        daysToWait = obj.daysToWait;
        if (kindOfTest == 'leitner') {
            numOfCardsToLearnDisplay = numOfQuestions + " " + i18n.gettext("due");
            if (!isNaN(obj.numRecommendedForStudyTarget) && obj.numRecommendedForStudyTarget > 0) {
                numOfCardsToLearnDisplay += ' <div style=\"font-size: 0.4em; color: #aaa;\"><b>' + (obj.numRecommendedForStudyTarget == 'null' ? 0 : obj.numRecommendedForStudyTarget) + ' ' + i18n.gettext('new for study target') + '</div>';
            }
            if (!isNaN(obj.numOfNotYetPlayed) && obj.numOfNotYetPlayed > 0) {
                numOfCardsToLearnDisplay += ' <div style=\"font-size: 0.4em; color: #aaa;\"><b>' + (obj.numOfNotYetPlayed == 'null' ? 0 : obj.numOfNotYetPlayed) + '</b> ' + i18n.gettext('new available') + '</div>';
            }
        } else {
            numOfCardsToLearnDisplay = numOfQuestions;
        }

        var pointsToPass = 0;
        if ($("#learn-options-modal .points-to-pass").length > 0) {
            if ($("#learn-options-modal #isExam").val() == "true") {
                if (obj.pointsToPassGeneral > 0 && obj.numOfCardsInExam > 0) {
                    $("#learn-options-modal .points-to-pass").show();
                    pointsToPass = obj.pointsToPassGeneral;
                    var pointsToPassPercentageValue = obj.pointsToPassGeneral / obj.numOfCardsInExam;
                    pointsToPass = Math.round(pointsToPassPercentageValue * numOfQuestions);
                } else {
                    pointsToPass = Math.round(2 / 3 * numOfQuestions);
                }
                $("#learn-options-modal .pointsToPass").html(pointsToPass);
            } else {
                $("#learn-options-modal .points-to-pass").hide();
            }
        }

//        if ($("#learn-options-modal .negative-scores").length > 0) {
//            if ($("#learn-options-modal #isExam").val() == "true" && pointsToPass > 0) {
//                $("#learn-options-modal .negative-scores").show();
//                if (obj.negativeScores) {
//                    $("#learn-options-modal .negativeScores").html(i18n.gettext("Correct: 2 points; Wrong: -1 points"));
//                } else {
//                    $("#learn-options-modal .negativeScores").html(i18n.gettext("Correct: 2 points; Wrong: 0 points"));
//                }
//            } else {
//                $("#learn-options-modal .negative-scores").hide();
//            }
//        }

        $("#learn-options-modal .cards-to-learn:visible").html(numOfCardsToLearnDisplay);
        learnOptionsWarningSentence(obj);

    });

}

function lomKeywordValueChange(el) {
    debug("*** lomKeywordValueChange");
    var boxId = $('#learn-options-modal-cardset-selector').val();
    var learningKeywordValue = $(".learn-option #tags").val();
    if (!isNaN(parseFloat(boxId)) && isFinite(boxId) && boxId > 0) {
        $.get("kartensatz-karten-" + boxId + "?learningKeywordValue=" + learningKeywordValue, function (data) {
            refreshNumOfCardsInLearnOptionsModal(boxId);
        });
    } else {
        var option = "learningKeywordValue";
        $.get("confsaver?setLearningOption=" + option + "&val=" + learningKeywordValue, function (data) {
            refreshNumOfCardsInLearnOptionsModal(boxId);
        });
    }
}

function refreshLearnOptionsModal(boxId) {
    var lom = $("#learn-options-modal");
    var enterKeywordsString = i18n.gettext("Enter keywords (separate by enter or comma)");
    debug("Initializing tags input.");
    $(".learnOptionKeywordSelectBlock #tags").tagsInput({
        'autocomplete_url': function (request, response) {
            var boxId = $("#learn-options-modal-cardset-selector").val();
            var url = 'keywords?boxId=' + boxId + '&term=' + request.term;
            $.get(url, function (data) {
                data = JSON.parse(data);
                response(data);
            });
        },
        'autocomplete': {appendTo: ".learnOptionKeywordSelectBlock .keyword-suggestions"},
        'onAddTag': lomKeywordValueChange,
        'onRemoveTag': lomKeywordValueChange,
        'defaultText': enterKeywordsString,
        'height': '73px'
    });
    $("#tags_tag").on('focusout', function () {
        debug("*** Triggering enter.");
        triggerEnter("#tags_tag");
    });
    $(".ui-autocomplete-input").css("width", "280px");

    var learnAll;

    var studyConfUrl = 'api/v5/studyconf';
    var hasBox = false;
    if (!isNaN(parseFloat(boxId)) && isFinite(boxId) && boxId > 0) {
        studyConfUrl = studyConfUrl + '/' + boxId;
        debug("studyConfUrl is: " + studyConfUrl);
        hasBox = true;
    }

    $.get(studyConfUrl, function (data) {
        var studyConf = $.parseJSON(data.toString().trim());

        var boxes = studyConf.activeCardboxes;
        var folders = studyConf.folders;
        if ($("#learn-options-modal-folder-selector").length > 0) {
            $("#learn-options-modal-folder-selector").empty();
            if (($("html").data("theme") != 'beck-pl' && folders.length > 0) || folders.length > 1) {
                $("#folderLearnOptions").show();
            } else {
                $("#folderLearnOptions").hide();
            }
            $("#learn-options-modal-folder-selector").append('<option value="">' + i18n.gettext('All') + '</option>');
            $.each(folders, function (index, folder) {
                var selector;
                if (studyConf.learningConf.learningFolder == folder.folderKey) {
                    selector = ' selected="selected"';
                } else {
                    selector = '';
                }
                $("#learn-options-modal-folder-selector").append('<option value="' + folder.folderKey + '" ' + selector + '>' + folder.folderName
                        + '</option>');
            });
            if ($("html").data("theme") == 'beck-pl' && folders.length == 1) {
                debug("We have only one folder.");
                // is there a cardset without folder?
                var foundBoxWithoutFolder = false;
                $.each(boxes, function (index, box) {
                    if (box.folderKey == "") {
                        foundBoxWithoutFolder = true;
                    }
                });
                if (foundBoxWithoutFolder) {
                    debug("There is a box without folder.");
                    $("#folderLearnOptions").show();
                } else {
                    debug("There is not box without folder.");
                    $("#learn-options-modal-folder-selector").val(folders[0].folderKey);
                    $.get("confsaver?setLearningOption=learningFolder&val=" + folders[0].folderKey);
                }
            } else {
                debug("No...");
            }
        } else {
            debug("No #2...");
        }

        var learningMaxNumber = studyConf.learningConf.learningMaxNumber;
        debug("learningMaxNumber is: " + learningMaxNumber);
        if (learningMaxNumber > 0) {
            if ($("#learn-options-modal-number-selector").length > 0) {
                $("#learn-options-modal-number-selector").val(learningMaxNumber);
            }
        }

        $("#learn-options-modal-cardset-selector").empty();
        $("#learn-options-modal-cardset-selector").append('<option value="">' + i18n.gettext('All active cardsets') + '</option>');
        $.each(boxes, function (index, box) {
            // box.cardBoxNbr, box.cardBoxName
            var selector;
            if (boxId == box.cardBoxNbr) {
                selector = ' selected="selected"';
            } else {
                selector = '';
            }
            $("#learn-options-modal-cardset-selector").append('<option value="' + box.cardBoxNbr + '" ' + selector + '>' + box.cardBoxName
                    + '</option>');
        });

        // learningConf

        if (hasBox) {
            learnAll = false;
            lom.find(".categories-section").show();
        } else {
            learnAll = true;
            lom.find(".categories-section").hide();
        }

        var conf = studyConf.learningConf;
        var isExam = $("#learn-options-modal #isExam").val();
        if (isExam != null && isExam !== '' && isExam !== 'undefined' && isExam == 'true') {
            $("#learn-options-modal-kindoftest-selector").val("allCards");
            lomKindOfTestChange($("#learn-options-modal-kindoftest-selector"));

        } else if (conf.learningMode != null && conf.learningMode !== '') {
            debug("We set the kind of test to conf.learningMode.");
            if (conf.learningMode == 'exam') {
                conf.learningMode = 'leitner';
                $('#learn-options-modal-kindoftest-selector').val(conf.learningMode);
                lomKindOfTestChange($("#learn-options-modal-kindoftest-selector"));
            } else {
                $('#learn-options-modal-kindoftest-selector').val(conf.learningMode);
            }
        }
        var selectedKindOfTest = $('#learn-options-modal-kindoftest-selector').val();
        debug("The selected kind of test is: " + selectedKindOfTest);
        if (isExam == 'true') {
//            $("#learnOptionsMaxNumberOfCardsSelector").show();
            $("#learnOptionsOrderSelector").hide();
        } else {
//            if ($("#learnOptionsMaxNumberOfCardsSelector").length > 0) {
//                $("#learnOptionsMaxNumberOfCardsSelector").hide();
//            }
            if ($("#learnOptionsOrderSelector").length > 0) {
                $("#learnOptionsOrderSelector").show();
            }
        }


        if (conf.learningOrder !== '') {
            $('#learn-options-modal-order-selector').val(conf.learningOrder);
        }

        $('.js-lom-extra.learningKeyword').prop('checked', conf.learningKeyword != 0 ? true : false);
        $('.js-lom-extra.learningFlipSides').prop('checked', conf.learningFlipSides != 0 ? true : false);
        $('.js-lom-extra.learningShowAnswer').prop('checked', conf.learningShowAnswer != 0 ? true : false);
        $('.js-lom-extra.learningCompareAutomatically').prop('checked', conf.learningCompareAutomatically != 0 ? true : false);
        $('.js-lom-extra.learningMchoiceRandom').prop('checked', conf.learningMchoiceRandom != 0 ? true : false);
        $('.js-lom-extra.learningGroupByCardsets').prop('checked', conf.learningGroupByCardsets != 0 ? true : false);
        $('.js-lom-extra.learningSkipNumOfCards').val(conf.skipNumOfCards);

        if (conf.learningKeyword == 1) {
            $("#learn-options-modal .learnOptionKeywordSelectBlock").show();
        } else {
            $("#learn-options-modal .learnOptionKeywordSelectBlock").hide();
        }

        // keywords
        if (!$("html").data("theme").startsWith("beck") && studyConf.keywordsAvailable) {
            $("#learnOptionsKeywordsBlock").show();
        } else {
            $("#learnOptionsKeywordsBlock").hide();
        }

        $(".learn-option #tags").importTags(studyConf.keywords.join(","));

        // refreshNumOfCardsInLearnOptionsModal(boxId);
        learnOptionsWarningSentence(conf);

        // categories

        /* BUILD CATEGORIES */
        var lomcl = lom.find(".categories-list");
        // hide and clear categories-list
        hideLomCats();
        lomcl.html('');
        if (!learnAll) {

            var categoriesBuffer = '\
			    		<p class="select-all-btn click"> \
		                        		' + i18n.gettext('All categories') + ' \
		                    		</p> \
		                    		<p class="deselect-all-btn click"> \
		                        		' + i18n.gettext('Unselect all') + ' \
		                    		</p> \
	                    		';
            $.each(studyConf.categories, function (key, val) {
                val.name;
                categoriesBuffer += '<p class="click selectCategory-' + val.id + '" data-id="' + val.id + '"> \
						                        ' + val.name + ' \
						                    </p>';
            });

            categoriesBuffer += '<p class="click selectCategory-0" data-id="0"> \
                                            ' + i18n.gettext('Without category') + ' \
                                        </p>';

            // debug(categoriesBuffer);
            lomcl.append(categoriesBuffer);

            if (studyConf.categoryPreselections.length > 0) {
                $.each(studyConf.categoryPreselections, function (key, val) {
                    $(".selectCategory-" + val).addClass('selected');
                    $(".selectCategory-" + val).prepend('\
								<i class="fa fa-check"></i> \
							');
                    // alert ('Kategorie ' + val + ' ist vorausgewaehlt!');
                });
                // check "all categories" if all categories are preselected
                if (studyConf.categoryPreselections.length === (studyConf.categories.length + 1)) {
                    lom.find(".select-all-btn").addClass("selected");
                    lom.find(".select-all-btn").prepend('\
								<i class="fa fa-check"></i> \
							');
                    lom.find(".categories-info span").html(i18n.gettext('All'));
                } else {
                    lom.find(".categories-info span").html('<span style="color:blue;">' + i18n.gettext('Selected') + '</span>');
                }
            } else {
                lom.find(".categories-info span").html('<span style="color:red;">' + i18n.gettext('None') + '</span>');
            }

            // add checkmarks to all selected items
            lomcl.find(".selected").prepend('\
					<i class="fa fa-check"></i> \
				');

            // create click functions for buttons
            lomcl.find(".click").not(".select-all-btn").not(".deselect-all-btn").click(function (event) {
                event.stopPropagation();
                $(this).siblings(".select-all-btn").removeClass("selected");
                $(this).siblings(".select-all-btn").find("i").remove();
                lom.find(".categories-info span").html('<span style="color:blue;">' + i18n.gettext("Selected") + '</span>');
                var catId = $(this).data('id');
                if ($(this).hasClass("selected")) {
                    $(this).find("i").remove();
                    // debug('unselect ' + catId);
                    // TODO
                    $.get("kartensatz-karten-" + boxId + "?unselectCategory=" + catId, function (data) {
                        // refreshLearnplanInfos(data);
                        refreshNumOfCardsInLearnOptionsModal($('#learn-options-modal-cardset-selector').val());
                    });
                    if ($(this).siblings(".selected").length == 0) {
                        lom.find(".categories-info span").html('<span style="color:red;">' + i18n.gettext('None') + '</span>');
                    }

                } else {
                    $(this).prepend('<i class="fa fa-check"></i>');
                    // debug('select ' + catId);
                    // TODO
                    $.get("kartensatz-karten-" + boxId + "?selectCategory=" + catId, function (data) {
                        // refreshLearnplanInfos(data);
                        refreshNumOfCardsInLearnOptionsModal($('#learn-options-modal-cardset-selector').val());
                    });

                    // if now all categories have been selected, check "All categories"
                    if (!$(this).siblings(".click").not(".select-all-btn").not(".deselect-all-btn").not(".selected").length) {
                        $(this).siblings(".select-all-btn").addClass("selected");
                        $(this).siblings(".select-all-btn").prepend('<i class="fa fa-check"></i>');
                        lom.find(".categories-info span").html(i18n.gettext("All"));
                    }
                }
                $(this).toggleClass("selected");
            });

            lomcl.find(".select-all-btn").click(function (event) {
                event.stopPropagation();
                $(this).siblings(".click").not(".deselect-all-btn").not("selected").addClass("selected").prepend('\
						<i class="fa fa-check"></i> \
					');
                $(this).addClass("selected");
                $(this).find("i").remove();
                $(this).prepend('<i class="fa fa-check"></i>');
                // debug('select all categories');
                // TODO
                $.get("kartensatz-karten-" + boxId + "?selectAllCategories", function (data) {
                    // refreshLearnplanInfos(data);
                    refreshNumOfCardsInLearnOptionsModal($('#learn-options-modal-cardset-selector').val());
                });
                lom.find(".categories-info span").html(i18n.gettext("All"));

            });

            // deselect all categories
            lomcl.find(".deselect-all-btn").click(function (event) {
                event.stopPropagation();
                $(this).siblings(".selected").find("i").remove();
                $(this).siblings(".selected").removeClass("selected");
                // debug('unselect all categories');
                // TODO
                $.get("kartensatz-karten-" + boxId + "?unselectAllCategories", function (data) {
                    // refreshLearnplanInfos(data);
                    refreshNumOfCardsInLearnOptionsModal($('#learn-options-modal-cardset-selector').val());
                });
                lom.find(".categories-info span").html('<span style="color:red;">' + i18n.gettext('None') + '</span>');
            });

        } // end if(!learnAll)

        $("#learn-options-modal .action-btn").data("id", $(this).attr("id"));
        setTimeout(function () {
            $("#learn-options-modal .action-btn").focus();
        }, 0);

        // learnmodeinfo

        var kindOfTest = $("#learn-options-modal-kindoftest-selector").val();
        var numOfCardsToLearnDisplay;
        var daysToWait;

        var obj = studyConf.learnmodeinfo;
        var numOfQuestions = obj.numOfQuestions;

        if (learningMaxNumber > 0) {
            numOfQuestions = Math.min(learningMaxNumber, numOfQuestions);
        }

        daysToWait = obj.daysToWait;
        if (kindOfTest == 'leitner') {
            numOfCardsToLearnDisplay = numOfQuestions + " " + i18n.gettext("due");
            if (!isNaN(obj.numRecommendedForStudyTarget) && obj.numRecommendedForStudyTarget > 0) {
                numOfCardsToLearnDisplay += ' <div style=\"font-size: 0.4em; color: #aaa;\"><b>' + (obj.numRecommendedForStudyTarget == 'null' ? 0 : obj.numRecommendedForStudyTarget) + ' ' + i18n.gettext('new for study target') + '</div>';
            }
            if (!isNaN(obj.numOfNotYetPlayed) && obj.numOfNotYetPlayed > 0) {
                numOfCardsToLearnDisplay += ' <div style=\"font-size: 0.4em; color: #aaa;\"><b>' + (obj.numOfNotYetPlayed == 'null' ? 0 : obj.numOfNotYetPlayed) + '</b> ' + i18n.gettext('new available') + '</div>';
            }
        } else {
            numOfCardsToLearnDisplay = numOfQuestions;
        }
        
        showOrHideSkipCardsOption();
        
        $("#learn-options-modal .cards-to-learn:visible").html(numOfCardsToLearnDisplay);
        learnOptionsWarningSentence(obj);

    });

}

function showOrHideSkipCardsOption() {
    var kindOfTest = $("#learn-options-modal-kindoftest-selector").val();
    var learningOrder = $("#learn-options-modal-order-selector").val();
        
    if (kindOfTest == "allCards" && learningOrder == "fixed") {
        $("#learn-options-modal #learnOptionsSkipCardsSection").show();
    } else {
        $("#learn-options-modal #learnOptionsSkipCardsSection").hide();
    }
}

/* LEARN OPTIONS MODAL */

function learnOptionsWarningSentence(conf) {
    debug("learnOptionsWarningSentence");
    var warningSentence = '';
    var learningRestrictions = [];
//    if (conf.notKnownLimit > 0) {
//        learningRestrictions.push(sprintf(i18n.gettext("maximal %s nicht gewusste Karten im Lernplan"),conf.notKnownLimit));
//    }
    if ("questionsPerDay" in conf && conf.questionsPerDay > 0 && conf.learningMode == 'leitner') {
        learningRestrictions.push(sprintf(i18n.gettext("a maximum of %s studied cards per day"), conf.questionsPerDay));
    }
    if ("newQuestionsPerDay" in conf && conf.newQuestionsPerDay > 0 && conf.learningMode == 'leitner') {
        learningRestrictions.push(sprintf(i18n.gettext("a maximum of %s new cards per day"), conf.newQuestionsPerDay));
    }
    if (learningRestrictions.length > 0) {
        warningSentence = "<div style=\"margin-top:10px;margin-bottom:10px;font-size:0.8em;\"><span style=\"color:red;\">" + i18n.gettext("Studying is restricted to") + " " + learningRestrictions.join(", ") + ". </span>";

        if (typeof conf.academySentence != 'undefined' && conf.academySentence.length > 0) {
            warningSentence += "<br>" + "<span>" + conf.academySentence + "</span>";
        } else {
            warningSentence += " <a class=\"bright-link click\" href=\"settings\" onclick=\"closeModal();gotoLearnplanLimits();\">" + i18n.gettext("Settings") + "</a>";
        }

        warningSentence += "</div>";
    }
    $("#learn-options-modal-warningSentence").html(warningSentence);
}

function gotoLearnplanLimits() {
    loadPageByAjax('settings', function () {
        $('.js-tab-content').addClass('hidden');
        $('#tab4').removeClass('hidden');
        navBtnActive($('#navBtn4'));
        $('#learnplan-limits-block').css('border', '5px solid red');
    });
}

function gotoSettingsTab(i) {
    loadPageByAjax("settings", function () {
        $('.js-tab-content').addClass('hidden');
        $('#tab' + i).removeClass('hidden');
        navBtnActive($('#navBtn' + i));
    });
}

// open learn-options-modal button
function learnBtnClick(el) {
    var mystr = $(el).attr('id');
    var myarr = mystr.split("-");
    var boxId = myarr[1];
    $("#learn-options-modal").modal();
    $("#learn-option-learning-mode").show();
    $("#learn-options-modal .js-learningShowAnswer").show();
    // $("#learn-options-modal-number-selector").hide();
    $("#learn-options-modal .points-to-pass").hide();
//    $("#learn-options-modal .negative-scores").hide();
    $("#learn-options-modal #isExam").val('false');
    $("#learn-options-modal .btn.primary-color-light.action-btn").html('<i class="fa fa-retweet"></i><span>' + i18n.gettext("Study") + '</span>');
    $("#learn-options-modal .modal-hl").html('<i class="fa fa-gear"></i><span>' + i18n.gettext('Study options') + '</span>');
    refreshLearnOptionsModal(boxId);
    executeTour('study-config');
}

function examButtonClick(el) {
    var mystr = $(el).attr('id');
    var myarr = mystr.split("-");
    var boxId = myarr[1];
    $("#learn-options-modal").modal();
    $("#learn-option-learning-mode").hide();
    $("#learn-options-modal .js-learningShowAnswer").hide();
    // $("#learn-options-modal-number-selector").show();
    $("#learn-options-modal .points-to-pass").show();
//    $("#learn-options-modal .negative-scores").show();
    $("#learn-options-modal #isExam").val('true');
    $("#learn-options-modal .btn.primary-color-light.action-btn").html('<i class="fa fa-graduation-cap"></i><span>' + i18n.gettext("Start exam") + '</span>');
    $("#learn-options-modal .modal-hl").html('<i class="fa fa-gear"></i><span>' + i18n.gettext('Exam options') + '</span>');
    refreshLearnOptionsModal(boxId, 'exam');
    executeTour('exam-config');
}

// start learning
function learnOptionsModalAction() {
    flashModal("<div style=\"text-align:center;\">" + i18n.gettext("Starting...") + "</div>", 3000);
    startLearning();
}

function showLomCats() {
    $("#learn-options-modal .categories-selection").show();
    $("#learn-options-modal .show-categories-btn").addClass("hidden");
    $("#learn-options-modal .hide-categories-btn").removeClass("hidden");
}

function hideLomCats() {
    $("#learn-options-modal .categories-selection").hide();
    $("#learn-options-modal .show-categories-btn").removeClass("hidden");
    $("#learn-options-modal .hide-categories-btn").addClass("hidden");
}

function changeLomCardset(el) {
    refreshLearnOptionsModal($(el).val());
}

function changeLomFolder(el) {
    debug("*** changeLomFolder");
    var selectedFolder = $("#learn-options-modal-folder-selector").val();
    $.get("confsaver?setLearningOption=learningFolder&val=" + selectedFolder, function (data) {
        refreshLearnOptionsModal("");
    });
}

function loadKeywordsForLearning(boxId) {
    //TODO load only keywords which are preselected and add them to the tags
    var keywordsUrl = "keywords";
    if (!isNaN(boxId) && boxId > 0) {
        keywordsUrl = "keywords?boxId=" + boxId;
    }
    $.getJSON(keywordsUrl, function (items) {
        $(".learn-option #tags").importTags(items.join(","));
    });
}

function lomExtraClick(el) {
    debug("*** lomExtraClick");
    var boxId = $("#learn-options-modal-cardset-selector").val();
    var option = $(el).data('option');
    var val;
    if ($(el).is(":checked")) {
        val = 1;
    } else {
        val = 0;
    }

    if (option == 'learningKeyword') {
        if (val == 1) {
            $("#learn-options-modal .learnOptionKeywordSelectBlock").show();
            // loadKeywordsForLearning(boxId);
        } else {
            $("#learn-options-modal .learnOptionKeywordSelectBlock").hide();
        }
    }

    if (!isNaN(parseFloat(boxId)) && isFinite(boxId) && boxId > 0) {
        $.get("kartensatz-karten-" + boxId + "?" + option + "=" + val, function (data) {
            if (option == 'learningKeyword') {
                refreshNumOfCardsInLearnOptionsModal(boxId);
            }
        });
    } else {
        $.get("confsaver?setLearningOption=" + option + "&val=" + val, function (data) {
            if (option == 'learningKeyword') {
                refreshNumOfCardsInLearnOptionsModal(boxId);
            }
        });
    }
}

function lomKindOfTestChange(el) {
    debug("*** lomKindOfTestChange");
    var boxId = $('#learn-options-modal-cardset-selector').val();
    var learnMode = $(el).val();
    if (learnMode == "exam") {
//        $("#learnOptionsMaxNumberOfCardsSelector").show();
        $("#learnOptionsOrderSelector").hide();
    } else {
//        if ($("#learnOptionsMaxNumberOfCardsSelector").length > 0) {
//            $("#learnOptionsMaxNumberOfCardsSelector").hide();
//        }
        if ($("#learnOptionsOrderSelector").length > 0) {
            $("#learnOptionsOrderSelector").show();
        }
    }
    if (!isNaN(parseFloat(boxId)) && isFinite(boxId) && boxId > 0) {
        $.get("kartensatz-karten-" + boxId + "?setDefaultLearningMode=" + learnMode, function () {
            refreshNumOfCardsInLearnOptionsModal(boxId);
        });
    } else {
        var option = "learningMode";
        $.get("confsaver?setLearningOption=" + option + "&val=" + learnMode, function () {
            refreshNumOfCardsInLearnOptionsModal('');
        });
    }
    showOrHideSkipCardsOption();
}

function lomOptionChange(el) {
    debug("*** lomOptionChange");
    var boxId = $('#learn-options-modal-cardset-selector').val();
    var value = $(el).val();
    var option = $(el).data('option');
    if (!isNaN(parseFloat(boxId)) && isFinite(boxId) && boxId > 0) {
        $.get("kartensatz-karten-" + boxId + "?" + option + "=" + value, function () {
//            if (option == 'learningSkipNumOfCards') {
//                refreshNumOfCardsInLearnOptionsModal(boxId);
//            }
        });
    } else {
        $.get("confsaver?setLearningOption=" + option + "&val=" + value, function() {
//            if (option == 'learningSkipNumOfCards') {
//                refreshNumOfCardsInLearnOptionsModal(boxId);
//            }
        });
    }
    showOrHideSkipCardsOption();
}