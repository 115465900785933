$(document).ready(function () {
    // call page specific function on normal page load if there is one
    // var pageName = $('html').attr("class").match(/(?:^|\s)page-(.*?)(?:$|\s)/)[1];
    // executePageFn(pageName);

    // make all textareas auto expand on input
    autosize($('textarea'));
});



// add easeOutQuad easing function to jquery easings
$.easing.easeOutQuad = function (t) {
    return -1 * t * (t - 2);
};




// NOTICE CLOSE BUTTON
function closeNotice(el) {
    $(el).closest(".notice").slideUp();
}


function learnplanChangeCardset(el) {
    var selectedBox = $(el).val();
    $('#lernplan-container').fadeTo(400, 0.3);
    $.ajax({
        type: "POST",
        url: "main",
        data: "selectedBox=" + selectedBox + "&module=learnplan",
        success: function (data) {
            $('#lernplan-container').html(data);
            $('#lernplan-container').fadeTo(400, 1.0);
        }
    });
}



$(document).on('click', ".play-video", function (e) {
    e.preventDefault();
    var fileName = $(this).attr('id');
    playVideo(fileName);
});



function sendValidationMail() {
    flashModal(i18n.gettext('Sending message...'));
    $.get("confsaver?sendValidationMail=1", function (data) {
        flashModal(i18n.gettext('Validation mail was sent.'));
    });
}

function sendValidationMailForUser() {
    var userId = $("#userId").val();
    $.get("profile-" + userId + "?sendValidationMail=1", function (data) {
        flashModal(i18n.gettext('Validation mail was sent.'));
    });
}

function setMailAddressValidatedForUser() {
    var userId = $("#userId").val();
    $.get("profile-" + userId + "?setMailValidated=1", function (data) {
        flashModal(i18n.gettext('Mail address was set to validated.'));
    });
}

function editStaticContent(el) {
    $(el).hide();
    $("#input-values").show();
    $("#static-title").hide();
    $(".js-save-static-content").show();
    $("#markAsReleaseSpan").show();
    $("#static-content").tinymce(editorConfig($("#static-content")));
    $("#static-content").css("margin-top", "30px");
    $("#static-content").css("border", "1px dashed red");
    // $("#static-title").tinymce(editorConfig($("#static-content")));
}

function saveStaticContent(el) {
    var page = $(el).data("page");
    var pageId = $("#pageId").val();
    var content = encodeURIComponent($('#static-content').html());
    var title = encodeURIComponent($('#title-input').val());
    var description = encodeURIComponent($('#description-input').val());
    var keywords = encodeURIComponent($('#keywords-input').val());
    var campaignId = encodeURIComponent($('#campaignId-input').val());
    var markAsRelease = $("#markAsRelease").is(":checked");
    var categoryId = $("#faq-category").val();
    var theme = $("#theme").val();
    var locale = $("#locale").val();
    // var url = $("#url").val();
    removeAllTinyMces();
    $(el).hide();
    $('.static-content').fadeTo(400, 0.3);
    flashModal(i18n.gettext('Saving...'));
    $.ajax({
        type: "POST",
        url: "static-content",
        data: "do=save"
            + "&pageId=" + pageId
            + "&content=" + content
            + "&title=" + title
            + "&url=" + page
            + "&description=" + description
            + "&keywords=" + keywords
            + "&campaignId=" + campaignId
            + "&released=" + markAsRelease
            + "&categoryId=" + categoryId
            + "&theme=" + theme
            + "&locale=" + locale,
        success: function (data) {
            $('.static-content').fadeTo(400, 1.0);
            $(".js-edit-static-content").show();
            flashModal(data);
            if (page.startsWith("faq")) {
                loadPageByAjax("faq");
            } else {
                loadPageToCenter(page);
            }
        }
    });
}

function deleteCoupon(el) {
    var couponId = $(el).data('coupon');
    var r = confirm(i18n.gettext("Delete really?"));
    if (r == true) {
        $.ajax({
            type: "POST",
            url: 'edit_coupon',
            data: 'delete=1&id=' + couponId,
            success: function () {
                loadPageToCenter('manage_coupons');
            }
        });
    }
}

function createCoupon() {
    var data = $('#createCouponForm').serialize();
    var nonce = getNonce();
    flashModal(i18n.gettext('Creating...'));
    $.ajax({
        // retry-sensitive
        type: "POST",
        url: 'manage_coupons',
        data: data + "&nonce=" + nonce,
        success: function (data) {
//            try {
            var obj = JSON.parse(data.trim());
            flashModal(obj.message);
            if (obj.success) {
                loadPageToCenter('manage_coupons');
            }
//            } catch (e) {
//                sendError(e.message + " | " + this.url + "?" + this.data + " | " + data);
//                return;
//            }
        }
    });
}



// LEARNPLAN BAR
function showLearnpointsBar() {
    $(".lp-bar").slideDown();
    $(".lp-bar-show-btn").hide();
    $('.lp-bar-hide-btn').show();
    setCookie('show-lp-bar', true, 9999);
}

function hideLearnpointsBar() {
    $(".lp-bar").slideUp();
    $(".lp-bar-hide-btn").hide();
    $('.lp-bar-show-btn').show();
    setCookie('show-lp-bar', false, 9999);
}


function playVideo(fileName) {
    var approot = $("html").data("approot");
    var titleString = '<p class="modal-hl">'
            + '<i class="fa fa-film"></i>'
            + '<span>' + i18n.gettext('Watch video') + '</span>'
            + '</p>';
    var vidString = '<object type="application/x-shockwave-flash" data="' + approot + 'player_flv_maxi.swf" width="640" height="480">'
            + '<param name="movie" value="' + approot + 'player_flv_maxi.swf" />'
            + '<param name="allowFullScreen" value="true" />'
            + '<param name="FlashVars" value="flv=https%3A//repetico-de.s3.amazonaws.com/videos/' + fileName + '&amp;showstop=1&amp;showvolume=1&amp;showfullscreen=1&amp;showiconplay=1" />'
            + '</object>';
    $("#general-purpose-modal-content").html(titleString + vidString);
    $("#general-purpose-modal").modal();
}

function noCopyAllowed() {
    generalPurposeModal(i18n.gettext("For bought cardsets, copying is not allowed."));
    return false;
}

function noContextAllowed() {
    generalPurposeModal(i18n.gettext("For bought or AI-created cards the context menu is not available."));
    return false;
}

function noSelectAllowed() {
    // generalPurposeModal(i18n.gettext("Bei gekauften Kartens&auml;tzen ist das Ausw&auml;hlen von Text nicht erlaubt."));
    return false;
}

function enableDocumentSelection(enable) {
    if (enable) {
        $("body").attr("oncopy", "");
        $("body").attr("oncontextmenu", "");
        $(".card question-text").attr("ondragstart", "");
        $(".card answer-text").attr("ondragstart", "");
        // $("body").attr("onselectstart","");
        document.oncopy = null;
        document.oncontextmenu = null;
        document.ondragstart = null;
        // document.onselectstart = null;
    } else {
        $("body").attr("oncopy", "noCopyAllowed();");
        $("body").attr("oncontextmenu", "noContextAllowed();");
        $(".card .question-text").attr("ondragstart", "noCopyAllowed();");
        $(".card .answer-text").attr("ondragstart", "noCopyAllowed();");

        // $("body").attr("onselectstart","noSelectAllowed();");
        document.oncopy = function () {
            return false;
        };
        document.oncontextmenu = function () {
            return false;
        };
        document.ondragstart = function () {
            return false;
        };
        // document.onselectstart = function() {
        //     return false;
        // };
    }
}



function strip_tags(input, allowed) {
    allowed = (((allowed || '') + '')
            .toLowerCase()
            .match(/<[a-z][a-z0-9]*>/g) || [])
            .join('');
    var tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi,
            commentsAndPhpTags = /<!--[\s\S]*?-->|<\?(?:php)?[\s\S]*?\?>/gi;
    return input.replace(commentsAndPhpTags, '')
            .replace(tags, function ($0, $1) {
                return allowed.indexOf('<' + $1.toLowerCase() + '>') > -1 ? $0 : '';
            });
}



function round(value, precision, mode) {
    var m, f, isHalf, sgn; // helper variables
    // making sure precision is integer
    precision |= 0;
    m = Math.pow(10, precision);
    value *= m;
    // sign of the number
    sgn = (value > 0) | -(value < 0);
    isHalf = value % 1 === 0.5 * sgn;
    f = Math.floor(value);

    if (isHalf) {
        switch (mode) {
            case 'PHP_ROUND_HALF_DOWN':
                // rounds .5 toward zero
                value = f + (sgn < 0);
                break;
            case 'PHP_ROUND_HALF_EVEN':
                // rouds .5 towards the next even integer
                value = f + (f % 2 * sgn);
                break;
            case 'PHP_ROUND_HALF_ODD':
                // rounds .5 towards the next odd integer
                value = f + !(f % 2);
                break;
            default:
                // rounds .5 away from zero
                value = f + (sgn > 0);
        }
    }

    return (isHalf ? value : Math.round(value)) / m;
}

//function getCookie(name) {
//    var value = "; " + document.cookie;
//    var parts = value.split("; " + name + "=");
//    if (parts.length == 2) {
//        return parts.pop().split(";").shift();
//    } else {
//        return null;
//    }
//}
//
//function setCookie(c_name, value, exdays) {
//    var exdate = new Date();
//    exdate.setDate(exdate.getDate() + exdays);
//    var c_value = escape(value)
//            + ((exdays == null) ? "" : ";path=/;expires=" + exdate.toUTCString());
//    debug("Setting cookie: " + c_name + "=" + c_value);
//    document.cookie = c_name + "=" + c_value;
//}

function closeBanner(id) {
    $.ajax({
        type: "GET",
        url: "closebanner",
        data: "id=" + id,
    }).done(function (data) {

    });
}

var shortInfoTimeout;

function shortInfo(text, timeoutVal) {
    if (isNaN(timeoutVal)) {
        timeoutVal = 4000;
    }
    if ($(".shortInfo").is(":visible")) {
//        $(".shortInfo").slideUp(200, function() {
        clearTimeout(shortInfoTimeout);
        $(".shortInfo").html(text);
        $(".shortInfo").slideDown();
        shortInfoTimeout = window.setTimeout('$(".shortInfo").slideUp();', timeoutVal);
//        });
    } else {
        // timeoutVal = typeof timeoutVal !== 'undefined' ? timeoutVal : 4000;
        clearTimeout(shortInfoTimeout);
        $(".shortInfo").html(text);
        $(".shortInfo").slideDown();
        shortInfoTimeout = window.setTimeout('$(".shortInfo").slideUp();', timeoutVal);
    }
}

function courseCheckAll() {
    for (var i = 0; i < document.spreadForm.elements.length; i++) {
        var e = document.spreadForm.elements[i];
        if (e.type == 'checkbox') {
            e.checked = true;
        }
    }
}

function courseUnCheckAll() {
    for (var i = 0; i < document.spreadForm.elements.length; i++) {
        var e = document.spreadForm.elements[i];
        if (e.type == 'checkbox') {
            e.checked = false;
        }
    }
}

if (typeof String.prototype.endsWith != 'function') {
    String.prototype.endsWith = function (str) {
        return this.slice(-str.length) == str;
    };
}

if (typeof String.prototype.startsWith != 'function') {
    String.prototype.startsWith = function (str) {
        return this.slice(0, str.length) == str;
    };
}



// MOBILE MENU PAGE CHANGE
function mobileMenuMore(e) {
    e.stopPropagation();
    $(".menu-page-one").hide();
    $(".menu-page-two").show();
}

function mobileMenuBack(e) {
    e.stopPropagation();
    $(".menu-page-two").hide();
    $(".menu-page-one").show();
}

function clickCounter(url) {
    $.ajax({
        type: "POST",
        url: "clickCounter",
        data: "url=" + url
    });
}



// Previously in learn-options-btns.js.
// Called from multiple places.
function refreshLearnplanInfos(data) {
//    try {
    var obj = JSON.parse(data.trim());

    if (obj.numOfQuestions == -1) {
        obj.learningDate = "";
        obj.infoText = "";
        obj.numOfQuestions = 0;
    }

    $("#learnmode-quantity").html('');
    $("#learnmode-quantity").html(obj.numOfQuestions);
    if (obj.learningDate != null) {
        $("#learnmode-info-ext").html(obj.learningDate);
        $("#learnmode-info-ext-icon").css('display', 'inline');
        $("#learnmode-info-ext").css('display', 'inline');
    } else if (obj.infoText != null) {
        $("#learnmode-info-ext").html(obj.infoText);
        $("#learnmode-info-ext-icon").css('display', 'none');
        $("#learnmode-info-ext").css('display', 'inline');
    } else {
        $("#learnmode-info-ext-icon").css('display', 'none');
        $("#learnmode-info-ext").css('display', 'none');
    }
    if (obj.numOfNotYetPlayed != null) {
        $(".learnplan-info .js-num-of-not-yet-played").html('(' + obj.numOfNotYetPlayed + ' ' + i18n.gettext('new') + ')');
    } else {
        $(".learnplan-info .js-num-of-not-yet-played").html('');
    }
//    } catch (e) {
//        sendError(e.message + " | " + this.url + "?" + this.data + " | " + data);
//        return;
//    }

}

String.prototype.endsWith = function (suffix) {
    return this.indexOf(suffix, this.length - suffix.length) !== -1;
};

function randomString(length) {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
}

function toggleNightMode(el, e) {
    e.stopPropagation();
    setCookie("main-background", '', 0);
    setCookie("main-color", '', 0);
    if ($('html').hasClass('darkmode')) {
        $('html').removeClass('darkmode');
        $('html').addClass('light');
        setCookie('darkmode', 'off', 0);
        $("#currentColorScheme").html(i18n.gettext("Light"));
    } else if ($('html').hasClass('light') && !$('html').hasClass('medium')) {
        // $('html').removeClass('light');
        $('html').addClass('medium');
        setCookie('darkmode', 'medium', 99999);
        $("#currentColorScheme").html(i18n.gettext("Medium"));
    } else if ($('html').hasClass('medium')) {
        $('html').removeClass('medium');
        $('html').removeClass('light');
        $('html').addClass('darkmode');
        setCookie('darkmode', 'on', 99999);
        $("#currentColorScheme").html(i18n.gettext("Dark"));
    }
    return false;
}

function scrollToAnchor(aid) {
    var aTag = $("a[name='" + aid + "']");
    $('html,body').animate({scrollTop: aTag.offset().top - 100}, 'slow');
}